const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-speedometer2",
      },
    ],
  },
  {
    route: "/orders",
    pages: [
      {
        sectionTitle: "Order Management",
        sectionKey: "orders",
        fontIcon: "bi-cart3",
        sub: [
          {
            heading: "Orders",
            route: "/orders",
            fontIcon: "bi-list-ul",
            allowedPermission: "readOrder"
          },
          {
            heading: "Order Products",
            route: "/order-products",
            fontIcon: "bi-tags",
            allowedPermission: "readOrderProduct"
          },
          {
            heading: "Pending Orders",
            route: "/pending-orders",
            fontIcon: "bi-exclamation-triangle",
            allowedPermission: "readPendingOrder",
            badge: true
          },
          {
            heading: "Pending Order Products",
            route: "/pending-order-products",
            fontIcon: "bi-tags",
            allowedPermission: "readPendingOrderProduct",
            badge: true
          },
          {
            heading: "Job Board",
            route: "/job-board",
            fontIcon: "bi-clipboard-check",
            allowedPermission: "readJobBoard"
          }
        ],
      }
    ],
  },
  {
    pages: [
      {
        heading: "Trading Partners",
        route: "/trading-partners",
        fontIcon: "bi-people",
        allowedPermission: "readTradingPartner"
      },
    ],
  },
  {
    route: "/products",
    pages: [
      {
        sectionTitle: "Product Management",
        sectionKey: "products",
        fontIcon: "bi-tags",
        sub: [
          {
            heading: "Products",
            route: "/products",
            fontIcon: "bi-list-ul",
            allowedPermission: "readProduct"
          },
          {
            heading: "Product Options",
            route: "/product-options",
            fontIcon: "bi-view-stacked",
            allowedPermission: "readProductMasterOption"
          },
          {
            heading: "Art SKU",
            route: "/art-sku",
            fontIcon: "bi-view-stacked",
            allowedPermission: "readArtSku"
          },
          {
            heading: "productSku",
            route: "/product-skus",
            fontIcon: "bi-upc-scan",
            allowedPermission: "readProductSKU"
          },
          {
            heading: "Product Weight",
            route: "/product-weight",
            fontIcon: "bi-handbag",
            allowedPermission: "readProductWeight"
          }
        ],
      }
    ],
  },
  {
    pages: [
      {
        heading: "Template Management",
        route: "/template-management",
        fontIcon: "bi-file-earmark-pdf",
        allowedPermission: "readTemplate"
      },
    ],
  },
  {
    pages: [
      {
        heading: "Shipping Method",
        route: "/shipping-method",
        fontIcon: "bi-truck",
        allowedPermission: "readShippingMethod"
      },
    ],
  },
  {
    route: "package-configuration",
    pages: [
      {
        sectionTitle: "Package Configuration",
        sectionKey: "package-configuration",
        fontIcon: "bi-box2",
        sub: [
          {
            heading: "Master Packages",
            route: "/master-packages",
            fontIcon: "bi-list-check",
            allowedPermission: "readMasterPackage"
          },
          {
            heading: "Shipping Packages",
            route: "/shipping-packages",
            fontIcon: "bi-truck",
            allowedPermission: "readShippingPackage"
          }
        ],
      }
    ],
  },
  {
    route: "/reports",
    pages: [
      {
        sectionTitle: "Reports",
        sectionKey: "reports",
        fontIcon: "bi-bar-chart-line",
        sub: [
          {
            heading: "Order Summary",
            route: "/order-summary-report",
            fontIcon: "bi-graph-up-arrow",
            allowedPermission: "ViewOrderSummaryReport"
          },
          {
            heading: "Order Detail",
            route: "/order-detail-report",
            fontIcon: "bi-bar-chart-steps",
            allowedPermission: "ViewOrderDetailReport"
          },
          {
            heading: "Product Sales",
            route: "/product-sales-report",
            fontIcon: "bi-pie-chart",
            allowedPermission: "ViewProductSalesReport"
          },
          {
            heading: "Job Progress",
            route: "/job-progress-report",
            fontIcon: "bi-journal-check",
            allowedPermission: "ViewJobProgressReport"
          },
        ],
      }
    ],
  },
  {
    route: "presses",
    pages: [
      {
        sectionTitle: "Settings",
        sectionKey: "settings",
        fontIcon: "bi-gear",
        sub: [
          {
            heading: "pressesListing",
            route: "/presses",
            svgIcon: "media/icons/duotune/art/art002.svg",
            fontIcon: "bi-newspaper",
            allowedPermission: "readPress"
          },
          {
            heading: "inksListing",
            route: "/inks",
            svgIcon: "media/icons/duotune/art/art002.svg",
            fontIcon: "bi-vector-pen",
            allowedPermission: "readInk"
          },
          {
            heading: "customersListing",
            route: "/customers",
            fontIcon: "bi-person-plus",
            allowedPermission: "readCustomer"
          },
          {
            heading: "roleManagement",
            route: "/roles",
            fontIcon: "bi-lock",
            allowedPermission: "readRole"
          },
          {
            heading: "Order Product Status",
            route: "/order-product-status",
            fontIcon: "bi-tags",
            allowedPermission: "readOrderProductStatus"
          },
          {
            heading: "Configuration Setting",
            route: "/configuration-setting",
            fontIcon: "bi-gear-wide-connected",
            allowedPermission: "readConfigurationSetting"
          },
          {
            heading: "Audit Logs",
            route: "/audit-logs",
            fontIcon: "bi bi-clock-history",
            allowedPermission: "readAuditLogs"
          }
        ],
      }
    ],
  },
];

export default DocMenuConfig;
