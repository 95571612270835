enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_ACTIONBUTTONS_ACTION = "setActionButtonsAction",
  PRODUCT_DEFAULT_PAGE_SIZE = "defaultPageNumber",
  PRODUCT_SEARCH = "productSearch",
  MASTER_PACKAGE_DEFAULT_PAGE_SIZE = "masterPageNumber",
  MASTER_PACKAGE_SEARCH = "masterPackageSearch",
  SHIPPING_PACKAGE_DEFAULT_PAGE_SIZE = "shippingPageNumber",
  PRESS_DEFAULT_PAGE_SIZE = "pressPageNumber",
  PRESS_SEARCH = "pressSearch",  
  INK_DEFAULT_PAGE_SIZE = "inkPageNumber",
  INK_SEARCH = "inkSearch",  
  CUSTOMER_DEFAULT_PAGE_SIZE = "customerPageNumber",
  CUSTOMER_SEARCH = "customerSearch",  
  ROLE_DEFAULT_PAGE_SIZE = "rolePageNumber",
  ROLE_SEARCH = "roleSearch",
  TRADING_PARTNER_DEFAULT_PAGE_SIZE = "tradingPartnerPageSize",
  TRADING_PARTNER_DEFAULT_PAGE_NUMBER = "tradingPartnerPageNumber",
  TRADING_PARTNER_SEARCH = "tradingPartnerSearch",  
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_ACTIONBUTTONS_MUTATION = "setActionButtonsMutation",
  RESET_ACTIONBUTTONS_MUTATION = "resetActionButtonsMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_PRODUCT_DEFAULT_PAGE_SIZE = "setDefaultPageNumber",
  SET_PRODUCT_SEARCH = "setProductSearch",
  SET_MASTER_PACKAGE_DEFAULT_PAGE_SIZE = "setMasterPageNumber",
  SET_MASTER_PACKAGE_SEARCH = "setMasterPackageSearch",
  SET_SHIPPING_PACKAGE_DEFAULT_PAGE_SIZE = "setShippingPageNumber",
  SET_PRESS_DEFAULT_PAGE_SIZE = "setPressPageNumber",
  SET_PRESS_SEARCH = "setPressSearch",
  SET_INK_DEFAULT_PAGE_SIZE = "setInkPageNumber",
  SET_INK_SEARCH = "setInkSearch",
  SET_CUSTOMER_DEFAULT_PAGE_SIZE = "setCustomerPageNumber",
  SET_CUSTOMER_SEARCH = "setCustomerSearch",
  SET_ROLE_DEFAULT_PAGE_SIZE = "setRolePageNumber",
  SET_ROLE_SEARCH = "setRoleSearch",
  SET_TRADING_PARTNER_DEFAULT_PAGE_SIZE = "setTradingPartnerPageSize",
  SET_TRADING_PARTNER_DEFAULT_PAGE_NUMBER = "setTradingPartnerPageNumber",
  SET_TRADING_PARTNER_SEARCH = "setTradingPartnerSearch",
}

export { Actions, Mutations };

