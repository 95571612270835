import GlobalConfig from "@/core/config/GlobalConfig";
export default {
  "customerModule": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "pressModule": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
	"inkModule": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "roleModule": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "productMasterPackageModule": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "tradingPartnerModule": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "productModule": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "productMasterOptionModule": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "orderProductStatusModule": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "shippingPackageModule": {
    "search": {
      "search": "",
      "productId": ""
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "templateManagementModule": {
    "search": {
      "search": "",
      "tradingPartnerId": ""
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "orderModule": {
    "search": {
      "search": "",
      "searchByOrderType": null,
      "searchByTradingPartner": null,
      "searchByOrderDate": null,
      "searchByOrderDueDate": null,
      "searchByOrderStatus": null
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "pendingOrderModule": {
    "search": {
      "search": "",
      "searchByOrderType": null,
      "searchByTradingPartner": null,
      "searchByOrderDate": null,
      "searchByOrderDueDate": null,
      "searchByOrderStatus": null
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "orderProductModule": {
    "search": {
      "search": "",
      "searchByTradingPartner": null,
      "searchByOrderDate": null,
      "searchByOrderDueDate": null,
      "searchByProductionDueDate": null,
      "searchByOrderStatus": null
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "pendingOrderProductModule": {
    "search": {
      "search": "",
      "searchByTradingPartner": null,
      "searchByOrderDate": null,
      "searchByOrderDueDate": null,
      "searchByProductionDueDate": null,
      "searchByOrderStatus": null
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "artSkuModule": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "shippingMethodModule": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "productSKUModule": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "auditLogModule": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  }
}