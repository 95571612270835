import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/product-weight',
  component: Layout2,
  children: [{
    path: "/product-weight",
    name: "product-weight",
    component: () => import("@/views/productweight/AddEditProductWeight.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Product Weight',
      auth: true,
      section: 'products',
      allowedPermission: "createProductWeight"
    }
  },{
    path: "/product-weight/import-product-weight",
    name: "import-product-weight",
    component: () => import("@/views/productweight/ProductWeightImport.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Import Product Weight',
      auth: true,
      section: 'products',
      allowedPermission: "importProductWeight"
    }
  },{
    path: "/product-weight/import-product-weight-history",
    name: "import-product-weight-history",
    component: () => import("@/views/productweight/WeightExcelImportHistory.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Import Product Weight History',
      auth: true,
      section: 'products',
      allowedPermission: "importProductWeightHistory",
    }
  }]
}]