import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/customers',
  component: Layout2,
  children: [{
    path: "/inks",
    name: "inks",
    component: () => import("@/views/inks/List.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Inks',
      auth: true,
      section: 'settings',
      allowedPermission: "readInk",
      module: 'inkModule'
    }
  }, {
    path: "/inks/create",
    name: "add-ink",
    component: () => import("@/views/inks/AddEditInk.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Add Ink',
      auth: true,
      section: 'settings',
      allowedPermission: "createInk",
      module: "inkModule"
    }
  }, {
    path: "/inks/edit/:id",
    name: "edit-ink",
    component: () => import("@/views/inks/AddEditInk.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Edit Ink',
      auth: true,
      section: 'settings',
      allowedPermission: "updateInk",
      module: "inkModule"
    }
  }
  ]
}]