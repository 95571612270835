import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: "/shipping-method",
  component: Layout2,
  children: [{
    path: "/shipping-method",
    name: "shipping-method",
    component: () => import("@/views/shippingmethod/List.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Shipping Method',
      auth: true,
      section: 'shipping-method',
      allowedPermission: "readShippingMethod",
      module: "shippingMethodModule"
    }
  }, {
    path: "/shipping-method/create/",
    name: "add-shipping-method",
    component: () => import("@/views/shippingmethod/AddEditShippingmethod.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Add Shipping Method',
      auth: true,
      section: 'shipping-method',
      allowedPermission: "createShippingMethod",
      module: "shippingMethodModule"
    }
  }, {
    path: "/shipping-method/edit/:id",
    name: "edit-shipping-method",
    component: () => import("@/views/shippingmethod/AddEditShippingmethod.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Edit Shipping Method',
      auth: true,
      section: 'shipping-method',
      allowedPermission: "updateShippingMethod",
      module: "shippingMethodModule"
    }
  }]
}]