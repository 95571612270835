import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";


interface ActionButton {
  pageActionButtons: Array<string>;
}
interface StoreInfo {
  actionButtons: ActionButton;
}

@Module
export default class ActionButtonsModule extends VuexModule implements StoreInfo {
  actionButtons = {} as ActionButton;
  /**
   * Get breadcrumb object for current page
   * @returns object
   */
   get getActionButton(): ActionButton {
    return this.actionButtons;
  }

  /**
   * Get breadcrumb array for current page
   * @returns object
   */
  get pageActionButtons(): Array<string> {
    return this.actionButtons.pageActionButtons;
  }

  @Mutation
  [Mutations.SET_ACTIONBUTTONS_MUTATION](payload) {
    this.actionButtons = payload;
  }

  @Mutation
  [Mutations.RESET_ACTIONBUTTONS_MUTATION]() {
    this.actionButtons = {} as ActionButton;
  }

  @Action
  [Actions.SET_ACTIONBUTTONS_ACTION](payload) {
    this.context.commit(Mutations.SET_ACTIONBUTTONS_MUTATION, payload);
  }
}
