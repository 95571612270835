import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/product-options',
  component: Layout2,
  children: [{
    path: "/product-options",
    name: "product-options",
    component: () => import("@/views/productmasteroption/List.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Product Options',
      auth: true,
      section: 'products',
      allowedPermission: "readProductMasterOption",
      module: "productMasterOptionModule"
    }
  }, {
    path: "/product-options/create",
    name: "add-product-option",
    component: () => import("@/views/productmasteroption/AddEditProductMasterOption.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Add Product Option',
      auth: true,
      section: 'products',
      allowedPermission: "createProductMasterOption",
      module: "productMasterOptionModule"
    }
  }, {
    path: "/product-options/edit/:id",
    name: "edit-product-option",
    component: () => import("@/views/productmasteroption/AddEditProductMasterOption.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Edit Product Option',
      auth: true,
      section: 'products',
      allowedPermission: "updateProductMasterOption",
      module: "productMasterOptionModule"
    }
  }, {
    path: "/product-options/view/:id",
    name: "view-product-option",
    component: () => import("@/views/productmasteroption/Details.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | View Product Option',
      auth: true,
      section: 'products',
      allowedPermission: "viewProductOption",
    }
  }]
}]