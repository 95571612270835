import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/art-sku',
  component: Layout2,
  children: [{
    path: "/art-sku",
    name: "art-SKU",
    component: () => import("@/views/artsku/List.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Art SKU',
      auth: true,
      section: 'products',
      allowedPermission: "readArtSku",
      module: "artSkuModule"
    }
  }, {
    path: "/art-sku/create",
    name: "add-art-sku",
    component: () => import("@/views/artsku/AddEditArtSku.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Add Art SKU',
      auth: true,
      section: 'products',
      allowedPermission: "createArtSku",
      module: "artSkuModule"
    }
  }, {
    path: "/art-sku/edit/:id",
    name: "edit-art-sku",
    component: () => import("@/views/artsku/AddEditArtSku.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Edit Art SKU',
      auth: true,
      section: 'products',
      allowedPermission: "updateArtSku",
      module: "artSkuModule"
    }
  },{
    path: "/art-sku/import",
    name: "import-art-SKU",
    component: () => import("@/views/artsku/ArtSkuImport.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Import Art SKU',
      auth: true,
      section: 'products',
      allowedPermission: "importArtSku",
      module: "artSkuModule"
    }
  },{
    path: "/art-sku/import-art-sku-history",
    name: "import-art-SKU-history",
    component: () => import("@/views/artsku/ArtSkuImportHistory.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Import Art SKU History',
      auth: true,
      section: 'products',
      allowedPermission: "importArtSkuHistory",
      module: "artSkuModule"
    }
  }]
}]