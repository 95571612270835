import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/trading-partners',
  component: Layout2,
  children: [{
    path: "/trading-partners",
    name: "trading-partners",
    component: () => import("@/views/tradingpartner/List.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Trading Partners',        
      auth: true,
      allowedPermission: "readTradingPartner",
      module: "tradingPartnerModule"
    }
  }, {
    path: "/trading-partner/create",
    name: "add-trading-partner",
    component: () => import("@/views/tradingpartner/AddEditTradingPartner.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Add Trading Partner',
      auth: true,
      allowedPermission: "createTradingPartner",
      module: "tradingPartnerModule"
    }
  }, {
    path: "/trading-partner/edit/:id",
    name: "edit-trading-partner",
    component: () => import("@/views/tradingpartner/AddEditTradingPartner.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Edit Trading Partner',
      auth: true,
      allowedPermission: "updateTradingPartner",
      module: "tradingPartnerModule"
    }
  },{
    path: "/trading-partners/import-product-excel-columns-mapping/:id",
    name: "import-product-excel-columns-mapping",
    component: () => import("@/views/products/ProductMapping.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Import Product Excel Columns Mapping',
      auth: true,
      allowedPermission: "productExcelMapping",
      module: "tradingPartnerModule"
    }
  },{
    path: "/trading-partners/import-order-excel-columns-mapping/:id",
    name: "import-order-excel-columns-mapping",
    component: () => import("@/views/orderproducts/OrderMapping.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Import Order Excel Columns Mapping',
      auth: true,
      allowedPermission: "orderExcelMapping",
      module: "tradingPartnerModule"
    }
  },{
    path: "/trading-partners/mapped-partner-sku/:id?",
    name: "mapped-partner-SKU",
    component: () => import("@/views/partnersku/List.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Mapped Partner SKU',
      auth: true,
      allowedPermission: "tradingPartnerSku",
      module: "tradingPartnerModule"
    }
  }]
}]