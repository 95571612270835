import Layout2 from '@/layout/Layout.vue';
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/products',
  component: Layout2,
  children: [{
    path: "/products",
    name: "products",
    component: () => import("@/views/products/List.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Products',
      auth: true,
      section: 'products',
      allowedPermission: "readProduct",
      module: "productModule"
    }
  },{
    path: "/products/product-imports",
    name: "import-products",
    component: () => import("@/views/products/ProductImport.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Import Products',
      auth: true,
      section: 'products',
      allowedPermission: "importProduct",
      module: "productModule"
    }
  },{
    path: "/products/import-products-history",
    name: "import-products-history",
    component: () => import("@/views/products/ProductExcelImportHistory.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Import Products History',
      auth: true,
      section: 'products',
      allowedPermission: "importProductHistory",
      module: "productModule"
    }
  },{
    path: "/products/imported-products/:id",
    name: "imported-products",
    component: () => import("@/views/products/ProductImportHistory.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Imported Products',
      auth: true,
      section: 'products',
      allowedPermission: "importProductHistory",
      module: "productModule"
    }
  },{
    path: "/products/edit-imported-product/edit/:productexcelid/:id",
    name: "edit-imported-product",
    component: () => import("@/views/products/EditProductImportHistory.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Edit Imported Product',
      auth: true,
      section: 'products',
      module: "productModule"
    }
  },{
    path: "/products/create",
    name: "add-product",
    component: () => import("@/views/products/AddEditProduct.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Add Product',
      auth: true,
      section: 'products',
      allowedPermission: "createProduct",
      module: "productModule"
    }
  },{
    path: "/products/edit/:id",
    name: "edit-product",
    component: () => import("@/views/products/AddEditProduct.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Edit Product',
      auth: true,
      section: 'products',
      allowedPermission: "updateProduct",
      module: "productModule"
    }
  }, {
    path: "/products/view/:id",
    name: "view-product",
    component: () => import("@/views/products/Details.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | View Product',
      auth: true,
      section: 'products',
      allowedPermission: "viewProduct",
      module: "productModule"
    }
  }, {
    path: "/products/assign-product-options/:id",
    name: "assign-product-options",
    component: () => import("@/views/products/AssignAdditionalOption.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Assign Product Options',
      auth: true,
      section: 'products',
      allowedPermission: "assignOptionProduct",
      module: "productModule"
    }
  }]
}]