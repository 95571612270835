import Layout2 from '@/layout/Layout.vue';
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/order-summary',
  component: Layout2,
  children: [{
    path: "/order-summary-report",
    name: "order-summary-report",
    component: () => import("@/views/reports/OrderSummary.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Order Summary Report',
      auth: true,
      section: 'reports',
      allowedPermission: "ViewOrderSummaryReport"
    }
  },{
    path: "/order-detail-report",
    name: "order-detail-report",
    component: () => import("@/views/reports/OrderDetail.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Order Detail Report',
      auth: true,
      section: 'reports',
      allowedPermission: "ViewOrderDetailReport"
    }
  },{
    path: "/product-sales-report",
    name: "product-sales-report",
    component: () => import("@/views/reports/ProductSales.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Product Sales Report',
      auth: true,
      section: 'reports',
      allowedPermission: "ViewProductSalesReport"
    }
  },{
    path: "/job-progress-report",
    name: "job-progress-report",
    component: () => import("@/views/reports/JobProgress.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Job Progress Report',
      auth: true,
      section: 'reports',
      allowedPermission: "ViewJobProgressReport"
    }
  }]
}]