import Layout2 from '@/layout/Layout.vue';
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/shipping-packages',
  component: Layout2,
  children: [{
    path: "/shipping-packages",
    name: "shipping-packages",
    component: () => import("@/views/productshippingpackage/List.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Shipping Packages',
      auth: true,
      section: 'package-configuration',
      allowedPermission: "readShippingPackage",
      module: "shippingPackageModule"
    }
  },{
    path: "/shipping-packages/import-packages",
    name: "import-packages",
    component: () => import("@/views/productshippingpackage/ImportPackages.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Import Packages',
      auth: true,
      section: 'package-configuration',
      module: "shippingPackageModule"
    }
  },{
    path: "/shipping-packages/import-packages-history",
    name: "import-packages-history",
    component: () => import("@/views/productshippingpackage/PackageExcelImportHistory.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Import Packages History',
      auth: true,
      section: 'package-configuration',
      module: "shippingPackageModule"
    }
  },{
    path: "/master-packages",
    name: "master-packages",
    component: () => import("@/views/productmasterpackage/List.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Master Packages',
      auth: true,
      section: 'package-configuration',
      allowedPermission: "readMasterPackage",
      module: 'productMasterPackageModule'
    }
  },{
    path: "/master-packages/create",
    name: "add-master-package",
    component: () => import("@/views/productmasterpackage/AddEditProductMasterPackage.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Add Master Package',
      auth: true,
      section: 'package-configuration',
      allowedPermission: "createMasterPackage",
      module: 'productMasterPackageModule'
    }
  },{
    path: "/master-packages/edit/:id",
    name: "edit-master-package",
    component: () => import("@/views/productmasterpackage/AddEditProductMasterPackage.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Edit Master Package',
      auth: true,
      section: 'package-configuration',
      allowedPermission: "updateMasterPackage",
      module: 'productMasterPackageModule'
    }
  }]
}]