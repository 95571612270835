import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: "/",
  component: Layout2,
  children: [{
    path: "/product-skus",
    name: "product-SKUs",
    component: () => import("@/views/productsku/SkuListing.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Product SKUs',
      auth: true,
      section: 'products',
      allowedPermission: "readProductSKU",
      module: "productSKUModule"
    }
  }]
}]