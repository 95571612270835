import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/audit-logs',
  component: Layout2,
  children: [{
    path: "/audit-logs",
    name: "audit-logs",
    component: () => import("@/views/auditlogs/List.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Audit Logs',
      auth: true,
      module: "auditLogModule"
    }
  }]
}]