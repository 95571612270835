import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/order-product-status',
  component: Layout2,
  children: [{
    path: "/order-product-status",
    name: "order-product-status",
    component: () => import("@/views/orderproductstatus/OrderProductStatus.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Order Product Status',
      auth: true,
      section: 'settings',
      allowedPermission: "readOrderProductStatus",
      module: "orderProductStatusModule"
    }
  },{
    path: "/order-product-status/create",
    name: "add-order-product-status",
    component: () => import("@/views/orderproductstatus/AddEditOrderProductStatus.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Add Order Product Status',
      auth: true,
      section: 'settings',
      allowedPermission: "createOrderProductStatus",
      module: "orderProductStatusModule"
    }
  },{
    path: "/order-product-status/edit/:id",
    name: "edit-order-product-status",
    component: () => import("@/views/orderproductstatus/AddEditOrderProductStatus.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Edit Order Product Status',
      auth: true,
      section: 'settings',
      allowedPermission: "updateOrderProductStatus",
      module: "orderProductStatusModule"
    }
  }]
}]