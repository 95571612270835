import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Mutations as rememberMutation } from "@/store/enums/RememberSearchEnums";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";

import customerRoutes from './customer';
import orderRoutes from './order';
import TemplateManagementRoutes from './templatemanagement';
import pendingOrderRoutes from './pending-order';
import productRoutes from './product';
import rolesRoutes from './role';
import inkRoutes from './ink';
import productMasterRoutes from './productmaster';
import pressesRoutes from './presses';
import productskuRoutes from './productsku';
import productWeightRoutes from './productweight';
import tradingPartnerRoutes from './trading-partner';
import productPackageRoutes from './productpackage';
import orderProductsRoutes from "./order-products";
import pendingOrderProductsRoutes from "./pending-order-products";
import reportsRoutes from "./reports";
import errorRoutes from './error';
import globalConstant from "@/core/data/globalConstant.js";
import settingsRoutes from "./settings";
import orderProductStatusRoutes from "./order-product-status";
import artSkuRoutes from "./art-sku";
import jobBoardRoutes from "./jobboard";
import auditLogs from "./audit-logs";
import shippingMethod from "./shippingmethod";
const routes= [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard2.vue"),
        meta: {
          title: globalConstant.general.APPTITLE +' | Dashboard',
          auth: true,
        },
      },
    ]
  },
  {
    path: "/",
    redirect: "/configuration-setting",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/configuration-setting",
        name: "configuration-setting",
        component: () => import("@/views/ConfigurationSetting.vue"),
        meta: {
          title: globalConstant.general.APPTITLE +' | Configuration Setting',
          auth: true,
          section: 'settings',
          allowedPermission: 'readConfigurationSetting',
          module: 'Configuraion Setting',
        },
      },
    ]
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          title: globalConstant.general.APPTITLE +' | Sign In'
        },
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          title: globalConstant.general.APPTITLE +' | Reset Password'
        },
      },
      {
        path: "/change-password/:id",
        name: "change-password",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/ChangePassword.vue"),
        meta: {
          title: globalConstant.general.APPTITLE +' | Change Password'
        },
      }
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
]
  .concat(orderRoutes)
  .concat(TemplateManagementRoutes)
  .concat(customerRoutes)
  .concat(productRoutes)
  .concat(inkRoutes)
  .concat(productMasterRoutes)
  .concat(tradingPartnerRoutes)
  .concat(pressesRoutes)
  .concat(rolesRoutes)
  .concat(productskuRoutes)
  .concat(productWeightRoutes)
  .concat(productPackageRoutes)
  .concat(errorRoutes)
  .concat(pendingOrderRoutes)
  .concat(orderProductsRoutes)
  .concat(reportsRoutes)
  .concat(pendingOrderProductsRoutes)
  .concat(settingsRoutes)
  .concat(orderProductStatusRoutes)
  .concat(artSkuRoutes)
  .concat(jobBoardRoutes)
  .concat(auditLogs)
  .concat(shippingMethod)
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // meta title
  document.title = to.meta && to.meta.title ? to.meta.title : globalConstant.general.APPTITLE;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.commit(Mutations.RESET_ACTIONBUTTONS_MUTATION);
  const token = JwtService.getToken();
  if (to.matched.some(record => record.meta.auth) && !token) {
    next('/sign-in')
    return
  }
  if (token) {
    ApiService.setHeader();
  }
  next()
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach((to, from, next) => {
  if (from.meta.module != to.meta.module) {
    store.commit(rememberMutation.CLEAR_SEARCH, (from.meta.module) ? from.meta.module : '');
  }  
})

export default router;
