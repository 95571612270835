import RememberSearchConfig from "@/core/config/RememberSearchConfig";
import GlobalConfig from "@/core/config/GlobalConfig";
import { Actions, Mutations } from "@/store/enums/RememberSearchEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
@Module
export default class StoreModule extends VuexModule {
  searchData: Object = RememberSearchConfig
  get getPerPage() {
    var rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["perPage"]) ? rememberSearchData[module]["perPage"] : "";
      }
    }
  }
  get getPageNumber() {
    var rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["pageNumber"]) ? rememberSearchData[module]["pageNumber"] : "";
      }
    }
  }
  get getPageSize() {
    var rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["pageSize"]) ? rememberSearchData[module]["pageSize"] : "";
      }
    }
  }
  get getSearch() {
    var rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["search"]) ? rememberSearchData[module]["search"] : "";
      }
    }
  }
  get getAssetSearch() {
    var rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["search"]) ? rememberSearchData[module]["search"] : "";
      }
    }
  }
  get getOrderSearch() {
    var rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["search"]) ? rememberSearchData[module]["search"] : "";
      }
    }
  }
  get getShippingPackageSearch() {
    var rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["search"]) ? rememberSearchData[module]["search"] : "";
      }
    }
  }
  get getTemplateManagementSearch() {
    var rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["search"]) ? rememberSearchData[module]["search"] : "";
      }
    }
  }
  //Common clear search mutation
  @Mutation
  [Mutations.CLEAR_SEARCH](module: string) {
    if (typeof module != "undefined" && (typeof this.searchData[module] != "undefined")) {
      let rememberModule = this.searchData[module];
      rememberModule["perPage"] = GlobalConfig.paginationConfig.perPage;
      rememberModule["pageNumber"] = GlobalConfig.paginationConfig.pageNumber;
      if (module == "orderModule" || module == "pendingOrderModule" || module == "orderProductModule" || module == "pendingOrderProductModule") {
        rememberModule["search"] = {
          "search": "",
          "searchByOrderType": null,
          "searchByTradingPartner": null,
          "searchByOrderDate": null,
          "searchByOrderDueDate": null,
          "searchByProductionDueDate": null,
          "searchByOrderStatus": null
        };
      } else if (module == "shippingPackageModule") {
        rememberModule["search"] = {
          "search": "",
          "productId": ""
        }
      }else if (module == "templateManagementModule") {
        rememberModule["search"] = {
          "search": "",
          "tradingPartnerId": ""
        }
      }
      else {
        rememberModule["search"] = "";
      }
    }
  }
  //Common per page mutation
  @Mutation
  [Mutations.SET_PER_PAGE](params: object) {
    if (typeof params["module"] != "undefined" && typeof params["value"] != "undefined") {
      this.searchData[params["module"]]["perPage"] = params["value"];
    }
  }
  //Common page number mutation
  @Mutation
  [Mutations.SET_PAGE_NUMBER](params: object) {
    if (typeof params["module"] != "undefined" && typeof params["value"] != "undefined") {
      this.searchData[params["module"]]["pageNumber"] = params["value"];
    }
  }
  //Common search mutation
  @Mutation
  [Mutations.SET_SEARCH](params: object) {
    if (typeof params["module"] != "undefined" && typeof params["value"] != "undefined") {
      this.searchData[params["module"]]["search"] = params["value"];
    }
  }

  //Order module search mutation
  @Mutation
  [Mutations.SET_ORDER_SEARCH](params: object) {
    if (typeof params["module"] != "undefined" && typeof params["params"] != "undefined") {
      this.searchData[params["module"]]["search"] = params["params"];
    }
  }

  //Shipping package module search mutation
  @Mutation
  [Mutations.SET_SHIPPING_PACKAGE_SEARCH](params: object) {
    if (typeof params["module"] != "undefined" && typeof params["params"] != "undefined") {
      this.searchData[params["module"]]["search"] = params["params"];
    }
  }

  //Template Management module search mutation
  @Mutation
  [Mutations.SET_TEMPLATE_MANAGEMENT_SEARCH](params: object) {
    if (typeof params["module"] != "undefined" && typeof params["params"] != "undefined") {
      this.searchData[params["module"]]["search"] = params["params"];
    }
  }

  //Common pagination per page action
  @Action
  [Actions.PER_PAGE](params: object) {
    this.context.commit(Mutations.SET_PER_PAGE, params);
  }
  //Common pagination page number action
  @Action
  [Actions.PAGE_NUMBER](params: object) {
    this.context.commit(Mutations.SET_PAGE_NUMBER, params);
  }
  //Common search action
  @Action
  [Actions.SEARCH](params: object) {
    this.context.commit(Mutations.SET_SEARCH, params);
  }
  //Order module search action
  @Action
  [Actions.ORDER_SEARCH](params: object) {
    this.context.commit(Mutations.SET_ORDER_SEARCH, params);
  }
  //Shipping package module search action
  @Action
  [Actions.SHIPPING_PACKAGE_SEARCH](params: object) {
    this.context.commit(Mutations.SET_SHIPPING_PACKAGE_SEARCH, params);
  }
  //Template Management module search action
  @Action
  [Actions.TEMPLATE_MANAGEMENT_SEARCH](params: object) {
    this.context.commit(Mutations.SET_TEMPLATE_MANAGEMENT_SEARCH, params);
  }
}