
import { defineComponent, onMounted, ref, reactive } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import ApiService from "@/core/services/ApiService";
import useEventBus from '../../composable/useEventBus'

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const { onEvent } = useEventBus()
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const pendingOrders = ref(0);
    const pendingOrderProducts = ref(0);

    onMounted(async () => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
      await getPendingOrdersCount();
    });

    onEvent('counterUpdate', (countData) => {
      if (countData.pendingOrders != undefined) {
        pendingOrders.value = countData.pendingOrders;
      }
      if (countData.pendingOrderProducts != undefined) {
        pendingOrderProducts.value = countData.pendingOrderProducts;
      }
    })

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    //Show the main menu if user have appropriate roles and permissions
    const checkMainMenuPermission = (permission) => {
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (
        permission == null ||
        per.includes(permission) ||
        role == "Super Admin"
      ) {
        return true;
      } else {
        return false;
      }
    };
    //Show the sub menu if user have appropriate roles and permissions
    const checkSubMenuPermission = (params) => {
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      let mapedPermissions = params.sub.map(function (el) {
        return el.allowedPermission;
      });
      let result = per.some((item) => mapedPermissions.includes(item));
      if (role == "Super Admin") {
        return true;
      } else {
        return result;
      }
    };
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const getPendingOrdersCount = async () => {
      await ApiService.post("/pending-orders-count", {})
      .then(({ data }) => {
        if (data.pendingOrderProduct) {
          pendingOrders.value = data.pendingOrders;
        }
        if (data.pendingOrderProduct) {
          pendingOrderProducts.value = data.pendingOrderProduct;
        }
      })
      .catch((error) => {
        console.log(error);
        //Toast the error here
      });
    }

    return {
      hasActiveChildren,
      checkSubMenuPermission,
      checkMainMenuPermission,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
      route,
      pendingOrders,
      pendingOrderProducts,
      getPendingOrdersCount
    };
  },
});
