import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/pending-orders',
  component: Layout2,
  children: [{
    path: '/pending-orders',
    name: 'pending-orders',
    component: () => import('@/views/orderproducts/PendingOrdersList'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Pending Orders',
      auth: true,
      section: 'orders',
      module: 'pendingOrderModule'
    }
  }, {
    path: ':id',
    name: 'pendingOrderDetails',
    component: () => import('@/views/orderproducts/PendingOrderDetails'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Pending Order Details',
      auth: true,
      section: 'orders',
      module: 'pendingOrderModule'
    }
  },{
    path: 'product/:order_id/:product_id',
    name: 'pendingOrderProduct',
    component: () => import('@/views/orderproducts/PendingOrderProductDetails'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Pending Order Item Details',
      auth: true,
      section: 'orders',
      module: 'pendingOrderProductModule',
    }
  }
  ]
}]