import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/pending-order-products',
  component: Layout2,
  children: [{
    path: '/pending-order-products',
    name: 'pending-order-products',
    component: () => import('@/views/orderproducts/ListPendingOrderProducts'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Pending Order Products',
      auth: true,
      section: 'orders',
      module: 'pendingOrderProductModule'
    }
  },{
    path: ':id',
    name: 'pending-order-details',
    component: () => import('@/views/orderproducts/PendingOrderDetails'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Pending Order Details',
      auth: true,
      section: 'orders',
      module: 'pendingOrderProductModule'
    }
  }, {
    path: 'product/:order_id/:product_id',
    name: 'pending-order-product',
    component: () => import('@/views/orderproducts/PendingOrderProductDetails'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Pending Order Product',
      auth: true,
      section: 'orders',
      module: 'pendingOrderProductModule',
    }
  }]
}]