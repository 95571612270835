export default {
  
  // General constant and values should define in this array
  general: {
    APPTITLE: 'HOFMP',
    SEARCH: 'Search',
    RESET: 'Reset',
    SRNO: 'Sr#',
    ACTIONS: 'Actions',
    ADD: 'Add',
    EDIT: 'Edit',
    VIEW: 'View',
    DELETE: 'Delete',
    SAVE: 'Save',
    SAVEANDBACK: 'Save & Back',
    CANCEL: 'Cancel',
    SKU: 'SKU',
    TYPE: 'Type',
    DUPLICATE: 'Duplicate',
    STATUS: 'Status',
    SORTORDER: 'Sort Order',
    HEIGHT: 'Height',
    WIDTH: 'Width',
    FILTER_COLUMNS: 'Filter Columns',
    DATE: 'Date',
    QUANTITY: 'Quantity',
    PRICE: 'PRICE',
    EMAIL: 'Email',
    UPLOADFILEPLACETEXT: 'Drop file here or <em>click to upload</em>',
    COLUMNMAPPING: 'Column Mapping',
    FINISH: 'Finish',
    SELECTTRADINGPARTNER: 'Select Trading Partner',
    SUCCESS: 'Success',
    NEXT: 'Next',
    PREVIOUS: 'Previous',
    DOWNLOAD: 'Download',
    DOWNLOAD_CSV: 'Download CSV',
    DOWNLOAD_EXCEL: 'Download Excel',
    INDEX: 'index',
    DATEANDTIME: 'Date & Time',
    FILENAME: 'File Name',
    DOWNLOADEXCELFILE: 'Download Excel File',
    PRICE: 'Price',
    DESCRIPTION: 'Description',
    DEFAULT: 'Default',
    MASTER_PACKAGE: 'Master Package',
    NAME: 'Name',
    TITLE: 'Title',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    BACK: 'Back',
    ALERT_CONFIMATION: 'OK',
    ALERT_CANCEL: 'Cancel',
    DELETE_MESSAGE: 'Do you really want to delete this record?',
    ALERT_WARNING: 'Warning',
    BACK: 'Back',
    SELECTPRODUCT: 'Select Product',
    SELECTSIZE: 'Select Size',
    SELECTPRODUCTEXCEL: 'Select Product Excel',
    UPDATE: 'Update',
    PRODUCTREQUIRED: 'Product is required',
    EXCELFILEREQUIRED: 'Excel Or CSV file is required',
    NOTE: 'Note',
    IMAGE: 'Image',
    DOWNLOAD_FILE: 'Download File',
    DOWNLOAD_EXCEL_FILE: 'Download Excel File',
    DOWNLOAD_CSV_FILE: 'Download CSV File',
    UPLOAD_FILE: 'Upload File',
    NOTIFY_SUCCESS: 'success',
    NOTIFY_ERROR: 'error',
    NOTIFY_WRONG_MESSAGE: 'Something went wrong',
    NO_DATA_FOUND: 'No Data Found!',
    DUPLICATE_MESSAGE: 'Do you really want to duplicate this record?',
    FOR_SIZE: 'For Size',
    FOR_SIZE_OPTION: 'For Size With Additional Option',
    FOR_SIZE_OPTION_COMBINATION: 'Size with Additional Option Combination',
    PRODUCTS: 'Products',
    ORDERS: 'Orders',
    IS_DEFAULT: 'Is Default',
    REFRESH: 'Refresh',
    LOADING: 'Loading..',
    TOTAL_ROWS: 'Total Rows',
    SKIPPED_ROWS: 'Skipped Rows',
    DUPLICATE_SKU: 'Duplicate SKU',
    SELECT_SKU_TYPE: 'Select SKU selection type',
    SELECT_SKU_TYPE_HELP: 'Set SKU type based on this selection import order sku will select',
    SKU_LENGTH: 'Enter SKU Length',
    SKU_LENGTH_HELP: 'Set SKU length. At the time of order import if product sku is greater then entered value then below art sku and product sku regex will apply',
    ART_SKU_REGEX_PATTERN: 'Enter Art SKU Regex Pattern',
    ART_SKU_REGEX_PATTERN_HELP: 'Set Art SKU regex pattern which will apply in order import. <br>Here is the sample pattern "/^[a-zA-Z]{3}[0-9]{9}/"',
    PRODUCT_SKU_REGEX_PATTERN: 'Enter Product SKU Regex Pattern',
    PRODUCT_REGEX_PATTERN_HELP: 'Set Product SKU regex pattern which will apply in order import. <br>Here is the sample pattern "/[a-zA-Z0-9]{11}$/"',
  },

  // Shipping package constant and values should define in this array
  package: {
    //Master Packages
    PACKAGE_NAME: 'Package Name',
    WEIGHT_UNIT: 'Weight Unit',
    LENGTH_UNIT: 'Length Unit',
    MAX_WEIGHT: 'Max Single Package Weight',
    BOX_WEIGHT: 'Box Weight',
    LENGTH: 'Length',
    PACKAGE_TYPE: 'Package Type',
    INTERNAL: 'Internal',
    EXTERNAL: 'External',
    CHOOSE_WEIGHT: 'Choose Weight Unit',
    CHOOSE_LENGTH: 'Choose Length Unit',
    PACKAGE_DETAILS: 'Package Dimensions',
    MAX_WEIGHT_DETAILS: 'Max Weight',
    BOX_WEIGHT_DETAILS: 'Box Weight',
    MASTER_PACKAGE_PLACEHOLDER: 'Search Master Packages',
    PACKAGE_CODE: 'Package Code',

    //Shipping Packages
    SHIPPING_PACKAGE_PLACEHOLDER: 'Search by SKU',
    MANAGE: 'Manage Shipping Package',
    INTERNAL_PACKAGE: 'Internal Package',
    EXTERNAL_PACKAGE: 'External Package',
    SELECT_INTERNAL_PACKAGE: 'Select Internal Package',
    SELECT_EXTERNAL_PACKAGE: 'Select External Package',
    IMPORT_PACKAGES: 'Import Packages',
    IMPORT_PACKAGES_HISTORY: 'Import Package History',

    //Validation messages
    PACKAGE_NAME_REQUIRED: 'Package name is required',
    PACKAGE_CODE_REQUIRED: 'Package code is required',
    MAX_WEIGHT_REQUIRED: 'Max Single Package Weight is required',
    BOX_WEIGHT_REQUIRED: 'Box Weight is required',
    LENGTH_REQUIRED: 'Length is required',
    HEIGHT_REQUIRED: 'Height is required',
    WIDTH_REQUIRED: 'Width is required',
    UNIQUE_PACKAGE_REQUIRED: 'Please enter unique Master Package code',
    EXTERNAL_PACKAGE_REQUIRED: 'External Package is required',

    //Import Packages
    FILL_DATA_HELP: 'Fill up data & help',
    EXCEL_COLUMN: 'Excel Column',
    REQUIRED: 'Required',
    UNIQUE: 'Unique',
    YES: 'Yes',
    NO: 'No',
    SELECT_PACKAGE_EXCEL: 'Select Product Shipping Packages Excel',
    PACKAGEIMPORTMESSAGE: 'Import packages process completed successfully',
    PACKAGEIMPORTREDIRECTBTN: 'Go To Shipping Packages',
    IMPORT_PACKAGE_HELP: 'Note: If master package name is not available in system, then system will add master packages from excel sheet and assign them to system SKU. If master package name is available in system, then system will only assign them to system SKU.',
    PACKAGE_STATISTICS: 'Package Statistics',
    NEW_MASTER_PACKAGES: 'New Master Packages',
    EXISTING_MASTER_PACKAGES: 'Existing Master Packages',
    ASSIGNED_INTERNAL_PACKAGES: 'Assigned Internal Packages',
    ASSIGNED_EXTERNAL_PACKAGES: 'Assigned External Packages',
    PACKAGEIMPORTHISTORYREDIRECTBTN: 'Go To Package Import History',
  },

  // General constant and values should define in this array
  tradingPartner: {
    ORDERMAPPING: 'Import Order Excel Columns Mapping',
    PRODUCTMAPPING: 'Import Product Excel Columns Mapping',
    SELECTPLATFORM: 'Select Trading Platform',
    TRADINGPARTNERNAME: 'Trading Partner Name',
    WEBSITEURL: 'Website URL',
    SHORTCODE: 'Shortcode',
    GRANTTYPE: 'Grant Type',
    CLIENTID: 'Client ID',
    SECRETID: 'Secret ID',
    ADDEXTRAFIELD: 'Add Extra Field',
    PULLTYPE: 'Type',
    KEYNAME: 'Key Name',
    KEYVALUE: 'Key Value',
    TYPEREQUIRED: 'Type is required',
    KEYNAMEREQUIRED: 'Key name is required',
    KEYVALUEREQUIRED: 'Key value is required',
    MAPPINGFIELD: 'Product Mapping',
    EXCELHEADERCOLUMN: 'Excel Header Column Name',
    ADDAPIPERAMETERBTN: 'Add API Parameter',
    DEFAULT_ADDRESS: 'Default Return Address',
    RETURN_ADDRESS: 'Return Address',
    NAME: 'Name',
    COMPANY: 'Company',
    ADDRESS1: 'Address1',
    ADDRESS2: 'Address2',
    CITY: 'City',
    STATE: 'State',
    STATE_CODE: 'State Code',
    COUNTRY: 'Country',
    COUNTRY_CODE: 'Country Code',
    PHONE: 'Phone Number',
    POSTAL_CODE: 'Postal Code',
    MAPPED_PARTNER_SKU: 'Mapped Partner SKU',
    ART_SKU_REGEX: 'Art SKU Regex'
  },


  // Option master constant and values should define in this array
  optionMaster: {
      OPTIONNAME: 'Name',
      OPTIONDESC: 'Description',
      OPTIONSORTORDER: 'Sort Order',
      OPTIONSTATUS: 'Status',
      ATTRIBUTENAME: 'Attribute Name',
      ATTRIBUTESKUCODE: 'Attribute SKU',
      ATTRIBUTEISDEFAULT: 'Is Default',
      ADDMOREATTRIBUTE: 'Add More Attribute',
      ATTRIBUTENAMEREQUIRED: 'Attribute name is required',
      ATTRIBUTECODEREQUIRED: 'Attribute SKU is required',
      ATTRIBUTESKUHELP: 'Attribute SKU will be added after the product and size SKU. (e.g: SXX | SBK).',
      ATTRIBUTE: 'Add Attribute'
  },

   // Orders constant and values should define in this array
  orders: {
    // Order Listing
    ID: 'ID',
    INVOICE_NO: 'Invoice No',
    PLATFORM: 'Platform',
    PARTNER_ORDER_ID: 'Partner Order ID',
    PARTNER: 'Partner',
    ORDER_TYPE: 'Type',
    TRADING_PARTNER: 'Trading Partner',
    CUSTOMER_DETAILS: 'Customer Details',
    ORDER_DATE:   'Order Date',
    ORDER_DUE_DATE: 'Due Date',
    PRODUCT_ITEMS: 'Products',
    PAYMENT_AMOUNT: 'Payment Amount',
    PAYMENT_AND_SHIPPING: 'Payment & Shipping',
    SHIPPING_AND_METHOD: 'Shipping Type & Method',
    ORDER_STATUS: 'Status',
    SEARCH_ORDERS: 'Search Orders',
    SELECT_PLATFORM: 'Select Platform',
    PULL_ORDERS: 'Pull Orders',
    IMPORT_ORDERS: 'Import Orders',
    PULL_ORDER_OPTIONS: 'Pull Order Options',
    IMPORT_ORDER_HISTORY: 'Import Order History',
    SHIPPING_CHARGES: 'Shipping Charges',
    SUBTOTAL: 'Subtotal',
    TAX: 'Tax',
    TOTAL_AMOUNT: 'Total Amount',
    DOWNLOAD_JOB_TICKET: 'Download Job Ticket',
    VIEWORDERDETAILS: 'View Order Details',
    VIEW_ORDER_PRODUCT_DETAILS: 'View Order Product Details',
    VIEWORDERPRODUCTS: 'View Order Products',
    DOWNLOAD_ORDER_INVOICE: 'Download Order Invoice',

    // Order Details
    ORDER_DETAILS: 'Order Details',
    PAYMENT_STATUS: 'Payment Status',
    ORDER_AMOUNT: 'Order Amount',
    CUSTOMER: 'Customer',
    EMAIL: 'Email',
    PHONE: 'Phone',
    COMPANY: 'Company',
    PAYMENT_METHOD: 'Payment method',
    PAYMENT_DATE: 'Payment Date',
    TRANSACTION_ID: 'Transaction Id',
    SHIPPING_METHOD: 'Shipping Method',
    SHIPPING_TYPE: 'Shipping Type',
    BILLING_ADDRESS: 'Billing Address',
    SHIPPING_ADDRESS: 'Shipping Address',
    PHONE_NUMBER: 'Phone Number',
    BLIND_SHIPPING_ADDRESS: 'Blind Shipping Address',
    ORDER_ITEMS: 'Order Products',
    PRODUCT_DETAILS: 'Product Details',
    MAPPED_SKUS: 'Mapped Sku',
    PRODUCT_SKU: 'Product SKU',
    SEPRATED_PRODUCT_SKU: 'Seprated Product SKU',
    SEPRATED_ART_SKU: 'Seprated Art SKU',
    ART_SKU: 'Art SKU',
    WEIGHT: 'Weight',
    ADDITIONAL_INFORMATION: 'Additional Information',
    PRODUCTION_DUE: 'Production Due Date',
    ORDER_EXTRA_FIELDS: 'Order Extra Fields',
    ORDER_ID: "Order Id",

    //Job ticket
    DOWNLOAD_JOB_TICKET_BTN: 'Download Job Tickets',
    DOWNLOAD_ALL_JOB_TICKET_BTN: 'Download All Job Tickets',
    DOWNLOAD_SELECTED_JOB_TICKET_BTN: 'Download Selected Orders Job Ticket',

    // Order Shipment
    MODIFY_DOWNLOAD_SHIPMENT_LABEL: 'Modify & Download Shipment Label',
    DOWNLOAD_SHIPMENT_LABEL: 'Download Shipment Label',
    TRACKING_NO: 'Tracking No',
    STATE_CODE: 'State Code',
    COUNTRY_CODE: 'Country Code',
    STATE_CODE_REQUIRED: 'State Code is required',
    COUNTRY_CODE_REQUIRED: 'Country Code is required',
    SHIIPING_METHOD_REQUIRED: 'Shipping method is required',
    SHIPPING_TYPE_REQUIRED: 'Shipping type is required',
    TRACKING_NO_REQUIRED: 'Tracking No is required',
    DOWNLOAD_ALL_SHIPMENT_LABEL: 'Download All Shipment Label',
    DOWNLOAD_SELECTED_SHIPMENT_LABEL: 'Download Selected Orders Shipment Label',
    SHIPMENT: 'Shipment',   
    SHIPPING_METHOD: 'Shipping Method',
    SHIPPING_TYPE: 'Shipping Type',
    PRODUCT_ITEM: 'Product Item',
    WEIGHT_UNIT: 'Weight Unit',
    LENGTH_UNIT: 'Length Unit',
    LENGTH: 'Length',
    HEIGHT: 'Height',
    WIDTH: 'Width',
    WEIGHT: 'Weight',
    QUANTITY: 'Quantity',
    ALL: 'All',
    FAILED: 'Failed',
    REGENERATE_SHIPPING_LABEL: 'Regenerate Shipping Label',
    VIEW_ERROR_FILE : 'View Error File',
    CREATE_SHIPMENT: 'Create Shipment',
    FROM_ADDRESS: 'Ship From Address',
    TO_ADDRESS: 'Ship To Address',
    SHIPMENT_TYPE: 'Shipment Type',
    ORDER_WISE: 'Order Wise',
    ORDER_PRODUCT_WISE: 'Order Product Wise',
    PACKAGE_CONFIGURATION: 'Package Configuration',
    MASTER_PACKAGE: 'Master Package',
    WEIGHT_UNIT_IS_REQUIRED: 'Weight unit is required.',
    WEIGHT_IS_REQUIRED: 'Weight is required.',
    QUANTITY_IS_REQUIRED: 'Quantity is required.',
    LENGTH_UNIT_IS_REQUIRED: 'Length unit is required.',
    LENGTH_IS_REQUIRED: 'Length is required.',
    HEIGHT_IS_REQUIRED: 'Height is required.',
    WIDTH_IS_REQUIRED: 'Width is required.',
    SHIPPING_METHOD_IS_REQUIRED: 'Shipping method is required.',    
    SHIPPING_TYPE_IS_REQUIRED: 'Shipping type is required.',
    GET_RATES: 'Get Rates',
    INVALID_FROM_ADDRESS_ERROR_MESSAGE: 'Invalid from address, Please update correct from address to create shipment.',
    ADDRESSES: 'Addresses',
    CLICK_SUGGESTED_ADDRESSES: 'Click to View Suggested Addresses',
    SHIPPING_COST: 'Shipping Cost',
    BUSINESS_DAYS_IN_TRANSIST: 'Business Days In Transist',
    ESTIMATE_DELIVERY_DATE: 'Estimate Delivery Date',
    LBS: 'LBS',
    KILOGRAM: 'Kilogram',
    INCH: 'Inch',
    CENTIMETER: 'Centimeter',
    NAME_REQUIRED: 'Name is Required',
    ADDRESS_1_REQUIRED: 'Address 1 is required',
    CITY_REQUIRED: 'City is required',
    STATE_REQUIRED: 'State is required',
    STATE_CODE_REQUIRED: 'State code is required',
    COUNTRY_REQUIRED: 'Country is required',
    COUNTRY_CODE_REQUIRED: 'Country code is required',
    POSTAL_CODE_REQUIRED: 'Postal code is required',
    PHONE_NUMBER_REQUIRED: 'Phone number is required',
    NAME: 'Name',
    ADDRESS_1: 'Address 1',
    ADDRESS_2: 'Address 2',
    CITY: 'City',
    STATE: 'State',
    COUNTRY: 'Country',
    POSTAL_CODE: 'Postal Code',
    CLOSE: 'Close',
    ADD_SITE_ADDRESS_ERROR_MESSAGE: 'Please add site address to create shipment.',
    SUGGESTED_ADDRESSES: 'Suggested Addresses',
    CLICK_SUGGESTED_ADDRESSES: 'Click to View Suggested Addresses',
    INVALID_ADDRESS: 'Invalid Address',
    SOMETHING_WENT_WRONG: 'Something went wrong',
    ADDRESS_VALIDATION_IN_PROGRESS: 'Address validation in progress',
    ADDRESS_VARIFIED: 'Address Verified',
    INVALID_TO_ADDRESS_ERROR_MESSAGE: 'Invalid ship to address, Please update correct ship to address to create shipment.',
    PLEASE_SELECT_AT_LEAST_ONE_PRODUCT: 'Please select at least one product',
    QUANTITY_IS_REQUIRED: 'Quantity is required.',
    QUANTITY_LIMIT_IS_EXCEED: 'Quantity limit is exceed.',
    CHOOSE_FROM_ADDRESS: 'Choose From Address',
    SELECT: 'Select',
    SUGGESTED_ADDRESSES: 'Suggested Addresses',
    CLICK_SUGGESTED_ADDRESSES: 'Click to View Suggested Addresses',

    // Packaging Slip
    DOWNLOAD_PACKAGING_SLIP: 'Download Packaging Slip',
    DOWNLOAD_ALL_PACKAGING_SLIP: 'Download All Packaging Slip',
    DOWNLOAD_SELECTED_PACKAGING_SLIP: 'Download Selected Orders Packaging Slip',

    //Order Watchlist
    ORDER_WATCHLIST: 'Watch List',
    ADD_ORDER_WATCHLIST: 'Add To Watch List',
    REMOVE_ORDER_WATCHLIST: 'Remove From Watch List',
  },
  
  // Product import constant and values should define in this array
  productImport: {
    PRODUCTIMPORT: 'Import Products',
    PRODUCTSTATISTICS: 'Products Statistics',
    PRODUCTEXCELFILE: 'Select Product Excel',
    SYSTEM_COLUMNS: 'System Columns',
    EXCELCOLUMNS: 'Excel Columns',
    TOTALPRODUCTFOUND: 'Total Products',
    NEWPRODUCTS: 'New Products',
    EXISTINGPRODUCTS: 'Existing Products',
    PRODUCTSBTN: 'Go To Products',
    PRODUCTHISTORYBTN: 'Go To Product Import History',
    SAMPLEPRODUCTFILE: 'Sample Product File Download',
    HISTORYBACKBTN: 'Back To History Page',
    SEARCHPRODUCT: 'Search By Product Name',
    SAVE_COLUMN : "Save this column mapping for future imports for the selected trading partner",
    SELECT_MAPPING_COLUMN: 'Select Mapping Column'
  },
  
  // Order Import constant and values should define in this array
  orderImport: {
    ORDERSIMPORT: 'Import Orders',
    UPLOADORDEREXCEL: 'Upload Order Excel File',
    ORDERSUCCESSMSG: 'Order Import Process Completed Successfully',
    PENDINGORDERFOUND: 'Pending Orders',
    PENDINGORDERPRODUCTFOUND: 'Pending Order Products',
    PENDINGORDERBTN: 'Go To Pending Orders Page',
    NEWORDERFOUND: 'Orders',
    NEWORDERPRODUCTFOUND: 'Order Products',
    NEWORDERBTN: 'Go To Orders Page',
    EXISTORDERFOUND: 'Existing Orders',
    EXISTORDERPRODUCTFOUND: 'Existing Order Products',
    SAMPLEORDERFILE: 'Sample Order File Download',
    SAVE_COLUMN: 'Save this column mapping for future imports for the selected trading partner',
    SELECT_ORDER_MAPPING: 'Select Order Column Mapping',
    ORDER_DETAILS: 'Order Details',
    CUSTOMER_DETAILS: 'Customer Details',
    SHIPPING_DETAILS: 'Shipping Address Details',
    BILLING_DETAILS: 'Billing Address Details',
    BLIND_DETAILS: 'Blind Address Details',
    PRODUCT_DETAILS: 'Product Details',
    ORDERSTATISTICS: 'Orders Statistics',
  },
  
  // Product constant and values should define in this array
  product: {
    PRODUCT_NAME: 'Product Name',
    SIZE: 'Size',
    PRODUCT_SIZES: 'Product Sizes',
    PRODUCT_SKU: 'Product SKU',
    ART_SKU: 'Art SKU',
    SIZE_SKU: 'Size SKU',
    IMPORT_PRODUCT: 'Import Products',
    IMPORT_PRODUCT_HISTORY: 'Import Products History',
    SAVEANDOPTION: 'Save & Assign Product Options',
    PRODUCT_DETAILS: 'Product Details',
    SIZE_DETAILS: 'Size Details',
    SKU_DETAILS: 'SKU Details',
    OPTION_DETAILS: 'Option Details',
    WEIGHT_DETAILS: 'Weight Details',
    SHIPPING_PACKAGES: 'Shipping Packages',
    PRODUCT_SKU_HELP: '<span>SKU that will be the first characters<br> of the System Product SKU (e.g: CAN).</span>',
    SIZE_SKU_HELP: '<span>SKU that will be added after the Product<br> SKU to generate System Product SKU (e.g: 11X11).</span>',
    ASSIGN_PRODUCT_OPTIONS: 'Assign Product Options',
    GENERATE_SKU: 'Generate SKU',
    NO_OPTION_FOUND: 'No Product Options Found',
    ATTRIBUTE: 'Attribute',
    ADD_SIZE: 'Add Size',
    MODIFY_SKU_HELP: '<span>This SKU will replace System SKU and System will<br> consider Modify SKU for all future operations.</span>',
    PRODUCT_OPTIONS: 'Product Options',
    PARTNER_SKU: 'Partner SKU',
    PRODUCT_GENERATE_SKU_HELP: '<span>If this option is disabled then the base SKU will not be generated.</span>',
    THICKNESS: 'Thickness',

    //Validation messages
    TITLE_REQUIRED: 'Title is required',
    HEIGHT_REQUIRED: 'Height is required',
    WIDTH_REQUIRED: 'Width is required',
    SIZE_SKU_REQUIRED: 'Size SKU is required',
    PRODUCT_NAME_REQUIRED: 'Product name is required',
    PRODUCT_SKU_REQUIRED: 'Product SKU is required'
  },

  // Presses constant and values should define in this array
  presses: {
    // Listing
    SEARCH_PRESSES: 'Search Presses',
    NAME: 'Name',
    DESCRIPTION: 'Description',
    
    // Add/Edit
    PRESS_NAME: 'Press Name',
    PRESS_DESCRIPTION: 'Description',
  },

  // Inks constant and values should define in this array
  inks: {
    // Listing
    SEARCH_INKS: 'Search Inks',
    NAME: 'Name',
    DESCRIPTION: 'Description',
    
    // Add/Edit
    INK_NAME: 'Ink Name',
    INK_DESCRIPTION: 'Description',
  },

  // Product weight constant and values should define in this array
  weight: {
    PRODUCTWEIGHT: 'Product Weights',
    PRODUCTNAME: 'Product Name',
    SIZE: 'Size',
    PRODUCTOPTION: 'Product Option',
    INDIVIDUALWEIGHT: 'Individual Weight',
    DEFINEWEIGHT: 'Select Options to filter SKUs',
    IMPORTWEIGHTBTN: 'Import Weight Data',
    WEIGHTIMPORT: 'Weight Import',
    DOWNLOADWEIGHTDATAEXCEL: 'Download Weight Data Excel',
    DOWNLOADWEIGHTDATACSV: 'Download Weight Data CSV',
    WEIGHTIMPORTMESSAGE: 'Weight Records Updated Successfully',
    WEIGHTIMPORTREDIRECTBTN: 'Go To Product Weight',
    WEIGHTEXCELEXPORTED: 'Weight content is exported',
    PRODUCTOPTIONVALIDATION: 'Please select product for import excel',
    WEIGHT_STATISTICS: 'Weight Statistics',
    IMPORT_PRODUCT_WEIGHT_HISTORY: 'Import Product Weight History',
  },
  
  // Template Management constant and values should define in this array
  templateManagement: {
    SETCONTENTTYPE: 'Set Content Type',
    COMMON: 'Common',
    TRADINGPARTNER: 'Trading Partner',
    TEMPLATENAME: 'Template Name',
    TEMPLATEMANAGEMENTCONTENT: 'Template Content',
    AVAILABLEVARIABLES: 'Available Variables',
    ALERT_MESSAGE: 'Are you sure you want to reset the template content?',
    DEFAULT_CONTENT: 'Reset Content'
  },

  // Product sku constant and values should define in this array
  productSku: {
      MODIFYSKU: 'Modify SKU',
      MODIFYWEIGHT: 'Modify Weight',
      MODIFYWEIGHT: 'Modify Weight',
      EXPORTSKU: 'Export SKUs',
      SKU_CSV_EXPORT: 'Export SKUs as CSV',
      SKU_EXCEL_EXPORT: 'Export SKUs as Excel',
      SEARCHSKUCODE: 'Search By SKU'
  },

  // Customers constant and values should define in this array
  customers: {
    // Listing
    NAME: 'Name',
    EMAIL: 'Email',
    ROLES: 'Roles',
    SEARCH_CUSTOMER_DETAILS: 'Search Customer Details',
  },

  orderProductStatus: {
    SEARCH_TITLE: 'Search By User/Message',
    CUSTOMER_STATUS: 'Customer Status',
    USE_ON_JOB_BOARD: 'Use on Job Board',
    STATUS_TITLE: 'Status Title',
    SET_AS: 'Set As',
    BADGE: 'Badge',
    HELP: 'Help',
    INTERNAL_STATUS: 'Internal Status'
  },

  partnerSku: {
    platform: 'Platform',
    SYSTEM_SKU: 'System SKU',
    SEARCH_TEXT: 'Search By Partner/System SKU',
    PARTNER_SKU_DATA: 'Partner SKU Data',
    PARTNER_SKU_DESCRIPTION: 'Partner SKU Description'
  },

  dashboard: {
    ORDERS_DETAILS: 'Order ID',
    TRADING_DETAILS: 'Trading Platform Details',
    ORDER_PRODUCT_DETAILS: 'Order Product Detils',
    PLATFORM_NAME: 'Platform Name',
    STORE_NAME: 'Store Name',
    TOTAL_ORDER_PLACED: 'Total Order Placed',
    TOTAL_ORDER_AMOUNT: 'Total Order Amount'
  },

  artSku: {
    ART_SKU_NAME: 'Art SKU Name',
    ART_SKU_CODE: 'Art SKU Code',
    ART_SKU_DESC: 'Art SKU Description',
    ART_SKU_PATH: 'Art SKU Path',
    ART_SEARCH: 'Search By Art SKU Name/Code',
    IMPORT_ART_BTN: 'Import Art SKU Data',
    EXPORT_ART_BTN: 'Export Art SKU Data',
    ART_IMPORT: 'Art SKU Import',
    ART_SKU_EXCEL_EXPORT: 'Art SKU Excel Export',
    ART_SKU_CSV_EXPORT: 'Art SKU CSV Export',
    ART_SKU_HISTORY: 'Import Art SKU History',
    ART_SKU_STATISTICS: 'Art SKU Statistics',
    SELECT_ART_EXCEL: 'Select Art SKU Excel',
    IMPORT_ART_SKU_HELP: 'Note: Art SKU name, path & description fields are optional in import file.',
    ART_IMPORT_REDIRECT_BTN: 'Go To Art SKU',
    ART_IMPORT_MESSAGE: 'Art SKU Records Inserted Successfully',
    DUPLICATE_ART_SKU: 'Duplicate Art SKU',
    TOTAL_ART_SKU_IMPORT: 'Total Art SKU Import',
    TOTAL_EXIST_ART_SKU_IMPORT: 'Total Exist Art SKU',
    SAMPLE_ART_FILE: 'Sample Art SKU File Download'
  },

  shippingMethod: {
    SHIPPING_METHOD_PLACEHOLDER: 'Search Shipping method'
  },

  report: {
    EXPORT_PRODUCT_SALES: 'Export Product Sales Data',
    PRODUCT_SALES_EXCEL_EXPORT: 'Product Sales Excel Export',
    PRODUCT_SALES_CSV_EXPORT: 'Product Sales CSV Export',
    EXPORT_JOB_PROGRESS: 'Export Job Progress Data',
    JOB_PROGRESS_EXCEL_EXPORT: 'Job Progress Excel Export',
    JOB_PROGRESS_CSV_EXPORT: 'Job Progress CSV Export'
  }

}

