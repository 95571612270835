import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: "/template-management",
  component: Layout2,
  children: [{
    path: "/template-management",
    name: "template-management",
    component: () => import("@/views/templatemanagement/List.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Template Management',
      auth: true,
      section: 'template-management',
      allowedPermission: "readTemplate",
      module: "templateManagementModule"
    }
  }, {
    path: "/template-management/create/",
    name: "add-template",
    component: () => import("@/views/templatemanagement/AddEditTemplateManagement.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Add Template',
      auth: true,
      section: 'template-management',
      allowedPermission: "createTemplate",
      module: "templateManagementModule"
    }
  }, {
    path: "/template-management/edit/:id",
    name: "edit-template",
    component: () => import("@/views/templatemanagement/AddEditTemplateManagement.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Edit Template',
      auth: true,
      section: 'template-management',
      allowedPermission: "updateTemplate",
      module: "templateManagementModule"
    }
  }]
}]