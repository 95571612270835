import Layout2 from '@/layout/Layout.vue';
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/customers',
  component: Layout2,
  children: [{
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/customers/List'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Customers',
      auth: true,
      section: 'settings',
      allowedPermission: "readCustomer",
      module: "customerModule"
    }
  }, {
    path: 'create',
    name: 'add-customer',
    component: () => import('@/views/customers/AddEditCustomer'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Add Customer',
      auth: true,
      section: 'settings',
      allowedPermission: "createCustomer",
      module: "customerModule"
    }
  }, {
    path: 'edit/:id',
    name: 'edit-customer',
    component: () => import('@/views/customers/AddEditCustomer'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Edit Customer',
      auth: true,
      section: 'settings',
      allowedPermission: "updateCustomer",
      module: "customerModule"
    }
  }]
}]