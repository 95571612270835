enum Actions {
  // action types
  PER_PAGE = "perPageAction",
  PAGE_NUMBER = "pageNumberAction",
  SEARCH = "searchAction",
  ORDER_SEARCH = "orderSearchAction",
  SHIPPING_PACKAGE_SEARCH = "shippingPackageSearchAction",
  TEMPLATE_MANAGEMENT_SEARCH = "templateManagementSearchAction"
}
enum Mutations {
  // mutation types
  SET_PER_PAGE = "perPageMutation",
  SET_PAGE_NUMBER = "pageNumberMutation",
  SET_SEARCH = "searchMutation",
  SET_ORDER_SEARCH = "orderSearchMutation",
  SET_SHIPPING_PACKAGE_SEARCH = "shippingPackageSearchMutation",
  SET_TEMPLATE_MANAGEMENT_SEARCH = "templateManagementSearchMutation",
  CLEAR_SEARCH = "clearMutation",
}
export { Actions, Mutations };