import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/settings',
  component: Layout2,
  children: [{
    path: "/settings",
    name: "settings",
    component: () => import("@/views/settings/AddEditSetting.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Settings',
      auth: true
    }
  }]
}]