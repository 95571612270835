//Get login users roles from local storage
export const loggedInUserRoles = () => {
    let roles = window.localStorage.getItem("roles");
    return roles;
}
//Get login users permissions from local storage
export const loggedInUserPermissions = () => {
    let permissions = window.localStorage.getItem("permissions");
    if (permissions != null) {
      permissions = permissions.split(",");
    }
    return permissions;
}