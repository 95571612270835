import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/presses',
  component: Layout2,
  children: [{
    path: "/presses",
    name: "presses",
    component: () => import("@/views/presses/List.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Presses',
      auth: true,
      section: 'settings',
      allowedPermission: "readPress",
      module: "pressModule"
    }
  }, {
    path: "/presses/create",
    name: "add-press",
    component: () => import("@/views/presses/AddEditPress.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Add Press',
      auth: true,
      section: 'settings',
      allowedPermission: "createPress",
      module: "pressModule"
    }
  }, {
    path: "/presses/edit/:id",
    name: "edit-press",
    component: () => import("@/views/presses/AddEditPress.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Edit Press',
      auth: true,
      section: 'settings',
      allowedPermission: "updatePress",
      module: "pressModule"
    }
  }]
}]