import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/order-products',
  component: Layout2,
  children: [{
    path: '/order-products',
    name: 'order-products',
    component: () => import('@/views/orderproducts/ListOrderProducts'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Order Products',
      auth: true,
      section: 'orders',
      module: 'orderProductModule',
    }
  },{
    path: ':id/:product_id?',
    name: 'orderDetails',
    component: () => import('@/views/orderproducts/Details'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Order Details',
      auth: true,
      section: 'orders',
      module: 'orderProductModule',
    }
  }, {
    path: 'product/:order_id/:product_id',
    name: 'orderProduct',
    component: () => import('@/views/orderproducts/OrderProductDetails'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Order Item Details',
      auth: true,
      section: 'orders',
      module: 'orderProductModule',
    }
  }]
}]