import Layout2 from '@/layout/Layout.vue'

export default [{
  path: '/',
  component: Layout2,
  children: [{
    path: '404',
    name: '404',
    component: () => import('@/views/crafted/authentication/Error404.vue'),
    meta: {
      auth: true,
    }
  }, {
    path: '500',
    name: '500',
    component: () => import('@/views/crafted/authentication/Error500.vue'),
    meta: {
      auth: true,
    }
  }, {
    path: '403',
    name: '403',
    component: () => import('@/views/crafted/authentication/Error403.vue'),
    meta: {
      auth: true,
    }
  }]
}]