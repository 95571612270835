
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import useEventBus from '../../composable/useEventBus'

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {
    Dropdown1,
  },
  setup() {
    const store = useStore();
    const { onEvent } = useEventBus();
    const { emitEvent } = useEventBus()
    onEvent('loader', (loaderObj) => {
      let index = actionButtons.value.findIndex((i) => i.action === loaderObj['action']);
      actionButtons.value[index].isLoader = loaderObj['save_loader'];
    })
    const setActionButtonEmit = ((actionName)=>{
      emitEvent('actionName',actionName)
    });
    const actionButtons = computed(() => {
      return store.getters.pageActionButtons;
    });
    return {
      toolbarWidthFluid,
      setActionButtonEmit,
      actionButtons
    };
  },
});
