import Layout2 from '@/layout/Layout.vue';
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/roles',
  component: Layout2,
  children: [{
    path: "/roles",
    name: "roles",
    component: () => import("@/views/roles/List.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Roles',
      auth: true,
      section: 'settings',
      allowedPermission: "readRole",
      module: 'roleModule'
    }
  }, {
    path: "/roles/create",
    name: "add-role",
    component: () => import("@/views/roles/AddEditRole.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Add Role',
      auth: true,
      section: 'settings',
      allowedPermission: "createRole",
      module: 'roleModule'
    }
  }, {
    path: "/roles/edit/:id",
    name: "edit-role",
    component: () => import("@/views/roles/AddEditRole.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Edit Role',
      auth: true,
      section: 'settings',
      allowedPermission: "updateRole",
      module: 'roleModule'
    }
  }]
}]