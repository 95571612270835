import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/job-board',
  component: Layout2,
  children: [{
    path: "/job-board",
    name: "job-board",
    component: () => import("@/views/jobboard/JobBoard.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Job Board',
      auth: true,
      section: 'orders',
      allowedPermission: "readJobBoard"
    }
  }]
}]