import Layout2 from '@/layout/Layout.vue'
import globalConstant from "@/core/data/globalConstant.js";

export default [{
  path: '/orders',
  component: Layout2,
  children: [{
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/orderproducts/List'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Orders',
      auth: true,
      section: 'orders',
      allowedPermission: "readOrder",
      module: "orderModule"
    }
  },{
    path: ':id/:product_id?',
    name: 'order-details',
    component: () => import('@/views/orderproducts/Details'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Order Details',
      auth: true,
      section: 'orders',
      allowedPermission: "viewOrder",
      module: "orderModule"
    }
  },{
    path: 'create',
    name: 'manual-order',
    component: () => import('@/views/orderproducts/AddEditOrder'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Manual Order',
      auth: true,
      section: 'orders',
      allowedPermission: "createOrder",
      module: "orderModule"
    }
  },{
    path: "/orders/import-orders",
    name: "import-orders",
    component: () => import("@/views/orderproducts/OrderImport.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Import Orders',
      auth: true,
      section: 'orders',
      allowedPermission: "importOrder",
      module: "orderModule"
    }
  },{
    path: "/orders/import-orders-history",
    name: "import-orders-history",
    component: () => import("@/views/orderproducts/OrderExcelImportHistory.vue"),
    meta: {
      title: globalConstant.general.APPTITLE +' | Import Orders History',
      auth: true,
      section: 'orders',
      allowedPermission: "importOrderHistory",
      module: "orderModule"
    }
  }, {
    path: 'product/:order_id/:product_id',
    name: 'order-product',
    component: () => import('@/views/orderproducts/OrderProductDetails'),
    meta: {
      title: globalConstant.general.APPTITLE +' | Order Product',
      auth: true,
      section: 'orders',
      module: 'orderProductModule',
    }
  }]
}]