
import { defineComponent, onMounted, ref } from "vue";
import KTSearch from "@/layout/header/partials/Search.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import useEventBus from '../../composable/useEventBus'
import { loggedInUserProfile } from "@/composable/get-profile.js";

export default defineComponent({
  name: "topbar",
  components: {
    KTSearch,
    KTNotificationsMenu,
    KTQuickLinksMenu,
    KTUserMenu,
  },
  setup() {
    const { onEvent } = useEventBus()
    const profileImage = ref("/media/avatars/blank.png");
    onEvent('profileImg', (imgPath) => {
        profileImage.value = imgPath;
    });
    onMounted(async () => {
        profileImage.value = loggedInUserProfile()
    });
    return {
      profileImage,
    };
  }
});
